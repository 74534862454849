import React, { Component } from "react"
import "./ContactUs.css"

export default class ContactUs extends Component {
  render() {
    return (
      <section id="contactUs" className="contact-page">
        <article className="contact-form">
          <h1>Get In Touch With Us</h1>
          <form action="https://formspree.io/moqkwodj" method="POST">
            {/* <form name="contact" method="POST" data-netlify="true"> */}
            <div className="form-group">
              <input
                type="text"
                name="name"
                placeholder="name"
                className="form-control"
              />
              <input
                type="email"
                name="name"
                placeholder="email"
                className="form-control"
              />
              <textarea
                name="message"
                rows="5"
                placeholder="message"
                className="form-control"
              ></textarea>
            </div>
            <button type="submit" className="submit-btn btn">
              submit
            </button>
          </form>
        </article>
      </section>
    )
  }
}
