import React from "react"
import Container from "../components/container"
import SEO from "../components/seo"
import Contact from "../ContactUs"

const NotFoundPage = () => (
  <Container defKey="3">
    <SEO
      title="Contact Us"
      description="Contact Virtual Start to make the best 3d virtual tours for your own today"
    />
    <Contact />
  </Container>
)

export default NotFoundPage
